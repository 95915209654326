import React, { useCallback,useEffect, useState } from "react";
import { Col, Container, Form, InputGroup, Row} from "react-bootstrap";
import '../Servicios/Servicios.css';
import {map} from 'lodash';
import Pagination from 'react-responsive-pagination';
// Bootstrap 4.x styles included somewhere in the project
import 'bootstrap/dist/css/bootstrap.css';
import Banners from "../Home/Banners";
import { getPatentesApi } from "../../Api/Patentes";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
function Patentes(){
    const [totalPages,setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [busqueda,setBusqueda] = useState("");
    const [patentes,setPatentes] = useState(null);
    const [numPagina,setNumPorPagina] = useState(10);
    const getPatentes = useCallback( async(pagina,busq,numPag) => {
        setBusqueda(busq);
        //console.log(buscar);
        const formdata = {
            "busqueda": busq,
            "pagina": pagina,
            "num_registros":numPag,
         }
        var serv = await getPatentesApi(formdata);
        setPatentes(serv.patentes);
        setTotalPages(serv.totalPaginas);
      },[]);
      useEffect(() => {
        getPatentes(1,"",numPagina);
    }, [getPatentes]) 
    var setBusquedaFuncion = (valor) =>{
        setCurrentPage(1);
        getPatentes(1,valor,numPagina);        
     }
     var setPorPagina = (numPag) =>{
        setNumPorPagina(numPag);
        setCurrentPage(1);
        getPatentes(1,busqueda,numPag);        
     }
     function handlePageChange(page) {
        setCurrentPage(page);
        //console.log("controler normal",page);
        getPatentes(page,busqueda,numPagina);
        // ... do something with `page`
      }
    return (
        <>
            <Banners/>
            <div className="contenidoLogin">
                <div className='login'>
                    <div className="inicio cursorEstilo">
                        <div className="circuloBuap">
                            <h2>Patentes BUAP</h2>
                        </div>
                    </div>
                    <div className='cuerpoLogin'>
                        <div className="cuadroLoginConsultores">
                            <div className="filtros_pag">
                                    <Form.Group>
                                        <InputGroup>
                                            <Form.Label className="registros">Mostrar</Form.Label>&nbsp;
                                            <Form.Control onChange={event => setPorPagina(event.target.value)} as="select">
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="30">30</option>
                                                <option value="50">50</option>
                                            </Form.Control>&nbsp;
                                            <Form.Label className="registros">registros por página</Form.Label>
                                        </InputGroup>
                                        
                                    </Form.Group>
                                
                                        <Form.Group>
                                            <Form.Control placeholder="Busqueda"   
                                                id="busquedaInput"
                                                name="busquedaInput"
                                                type="text"
                                                onChange={event => setBusquedaFuncion(event.target.value)}
                                            />
                                        </Form.Group>
                                    
                                </div>
                                <div className='cuerpoLogin'>
                                    <a href={`/`} class="hrefa previous">&laquo; Atrás</a>
                                </div>
                                {
                                    patentes != null &&(
                                        <>
                                        <Table>
                                            <Thead>
                                                <Tr>
                                                    <Th className="borderTabla">
                                                        Expediente Patente
                                                    </Th>
                                                    <Th className="borderTabla">
                                                        Titulo
                                                    </Th>
                                                    <Th className="borderTabla">
                                                        Inventores
                                                    </Th>
                                                    <Th className="borderTabla">
                                                        Descripción
                                                    </Th>
                                                    <Th className="borderTabla">
                                                        Área Tecnológica
                                                    </Th>
                                                    <Th className="borderTabla">
                                                        Palabras Clave
                                                    </Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {
                                                map(patentes,(pat,index)=>(
                                                    <Tr key={index} className={pat.id %2 != 0?('fondoTabla'):('') }>
                                                        <Td className="borderTabla">
                                                            {pat.expediente}
                                                        </Td>
                                                        <Td className="borderTabla">
                                                            {pat.titulo}
                                                        </Td>
                                                        <Td className="borderTabla">
                                                            {pat.inventores}
                                                        </Td>
                                                        <Td className="borderTabla">
                                                            {pat.descripcion}
                                                        </Td>
                                                        <Td className="borderTabla">
                                                            {pat.area}
                                                        </Td>
                                                        <Td className="borderTabla">
                                                            {pat.palabra_clave}
                                                        </Td>
                                                    </Tr>
                                                ))
                                                }
                                            </Tbody>
                                        </Table>
                                        <br></br>
                                        <Pagination
                                        total={totalPages}
                                        current={currentPage}
                                        onPageChange={page => handlePageChange(page)}
                                        />
                                        </>
                                    )
                                }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Patentes;