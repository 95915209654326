import React, { useState , useEffect} from "react";
import Carousel from 'react-bootstrap/Carousel'
import imagen1 from '../../assets/ba1.jpg'
import imagen2 from '../../assets/ba2.jpg'
import imagen3 from '../../assets/ba3.jpg'
import "./Home.css";
const baner=[
    {imagen:'ba1.jpg'},
    {imagen:'ba2.jpg'},
    {imagen:'ba3.jpg'}
]
function Banners() {
    return (
     
        <Carousel slide={true} fade={true}>       
            <Carousel.Item key={1} >
                <a className="etiqueta-a" rel="noreferrer" target="_blank">
                    <img
                     className="imagen"
                    src={imagen1}
                    alt="Banner" />
                </a>
            </Carousel.Item>
            <Carousel.Item key={2}>
                <a className="etiqueta-a" rel="noreferrer" target="_blank">
                    <img
                       className="imagen"
                    src={imagen2}
                    alt="Banner" />
                </a>
            </Carousel.Item>
            <Carousel.Item key={3}>
                <a className="etiqueta-a" rel="noreferrer" target="_blank">
                    <img
                       className="imagen"
                    src={imagen3}
                    alt="Banner" />
                </a>
            </Carousel.Item>
        
      </Carousel>

    );
  }

  export default Banners;
