import React,{useEffect,useState,useCallback} from 'react' ;
import { Col, Container, Form, InputGroup, Row} from "react-bootstrap";
import Banners from "./Banners";
import ban1 from "../../assets/banner/ban1.jpeg";
import ban2 from "../../assets/banner/ban2.png";
import ban3 from "../../assets/banner/ban3.jpeg";
function Home() {
    return(
        <>
            <Banners />
            <div className="contenidoLogin">
                <div className='login'>
                    <div className='cuerpoLogin'>
                        <div className='cuadroLogin'>
                            <div className="containerLogin">
                            <Container>
                                 <Row className="fila">
                                    <Col md={4}>
                                        <InputGroup >
                                            <a className="cursorEstilo" href={`/servicios`}>
                                                <img src={ban1}
                                                 className="imagenServicio"
                                            
                                                />
                                                <div className="circuloServicios">
                                                    <h2>Servicios</h2>
                                                </div>
                                            </a>
                                        </InputGroup>
                                        &nbsp;
                                        
                                    </Col>
                                    <Col md={4}>
                                        <InputGroup >
                                            <a className="cursorEstilo" href={`/consultores`}>
                                                <img src={ban2}
                                                className="imagenServicio"
                                                
                                                />
                                                <div className="circuloServicios">
                                                    <h2>Consultores</h2>
                                                </div>
                                            </a>
                                        </InputGroup>
                                        &nbsp;
                                    </Col>
                                    <Col md={4}>
                                        <InputGroup>
                                            <a className="cursorEstilo" href={`/patentes`}>
                                                <img src={ban3}
                                                className="imagenServicio"
                                                
                                                />
                                                <div className="circuloServicios">
                                                    <h2>Patentes</h2>
                                                </div>
                                            </a>
                                            &nbsp;
                                           
                                        </InputGroup>
                                    </Col>
                                 </Row>
                                 
                               </Container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Home;