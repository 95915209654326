import { API_URL } from "../utils/constants";
export async function getConsultoresApi(formdata){
    try {
        const url= `${API_URL}Consultores/getConsultores`;
        const params = {
            method: 'POST',
            headers: {
               'Accept':'application/json',
               'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(formdata),
        };
        //console.log(params);
        const responde = await fetch(url,params);
        const result = await responde.json();
        return result;
    } catch (error) {
        console.log(error);
        return null;
    }
}