import React, { useCallback,useEffect, useState } from "react";
import {Button, Col, Container, Form, Row ,InputGroup} from "react-bootstrap";
import { getServiciosByIdApi } from "../../Api/Servicios";
import Banners from "../Home/Banners";
import { getServiciosApi } from "../../Api/Servicios";
import Pagination from 'react-responsive-pagination';
import {map} from 'lodash';
// Bootstrap 4.x styles included somewhere in the project
import 'bootstrap/dist/css/bootstrap.css';
function VerServicio(props) {
    const {id} = props.match.params;
    const [totalPages,setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [busqueda,setBusqueda] = useState("");
    const [servicios,setServicios] = useState(null);
    const [numPagina,setNumPorPagina] = useState(10);
    const getServicios = useCallback( async(pagina,busq,numPag) => {
        setBusqueda(busq);
        //console.log(buscar);
        const formdata = {
            "busqueda": busq,
            "pagina": pagina,
            "num_registros":numPag,
            "categoria":id,
         }
        var serv = await getServiciosApi(formdata);
        setServicios(serv.servicios);
        setTotalPages(serv.totalPaginas);
      },[]);
  
    useEffect(() => {
        getServicios(1,"",numPagina);
    }, [getServicios]) 
    var setBusquedaFuncion = (valor) =>{
        setCurrentPage(1);
        getServicios(1,valor,numPagina);        
     }
     var setPorPagina = (numPag) =>{
        setNumPorPagina(numPag);
        setCurrentPage(1);
        getServicios(1,busqueda,numPag);        
     }


    const [servicio,setServicio] = useState(null);
    const getServiciosId = useCallback( async() => {
        //console.log(buscar);
        const formdata = {
            "id": id,
         }

          var serv = await getServiciosByIdApi(formdata);
          setServicio(serv.servicio);
      },[]);
  
    useEffect(() => {
        getServiciosId();
    }, [getServiciosId]) 
    function handlePageChange(page) {
        setCurrentPage(page);
        //console.log("controler normal",page);
        getServicios(page,busqueda,numPagina);
        // ... do something with `page`
      }
    var mostrarOcultar = (id) =>{
        document.getElementById("divdetalles"+id).style.display =(document.getElementById("divdetalles"+id).style.display == 'none' ? 'block':'none')
    }
    return(
        <>
        <Banners/>
        <div className="contenidoLogin">
            <div className='login'>
                <div className='cuerpoLogin'>
                  <div className='cuadroLoginService'>
                  <div className="filtros_pag">
                        <Form.Group>
                            <InputGroup>
                                <Form.Label className="registros">Mostrar</Form.Label>&nbsp;
                                <Form.Control onChange={event => setPorPagina(event.target.value)} as="select">
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="50">50</option>
                                </Form.Control>&nbsp;
                                <Form.Label className="registros">registros por página</Form.Label>
                            </InputGroup>
                            
                        </Form.Group>
                      
                            <Form.Group>
                                <Form.Control placeholder="Busqueda"   
                                    id="busquedaInput"
                                    name="busquedaInput"
                                    type="text"
                                    onChange={event => setBusquedaFuncion(event.target.value)}
                                />
                            </Form.Group>
                          
                    </div>
                        <a href={`/servicios`} class="hrefa previous">&laquo; Atrás</a>
                        {
                            servicios != null && (
                                <>
                                 {
                                    map(servicios,(serv,index)=>(
                                        <>
                                        <Container className="containerMargin">
                                            <Row>
                                                <Col md={12}>
                                                    <div className="circulo cursorEstilo" title="Ver Detalles" onClick={() => mostrarOcultar(serv.id)}>
                                                        <h2 >{serv.unidad_academica}</h2>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                        <Container className="containerMargin">
                                            <Row>
                                                <Col className="divTitulo" md={12}>
                                                    <h3 title="Ver Detalles" className="cursorEstilo" onClick={() => mostrarOcultar(serv.id)} id={"botondetalles"+serv.id}>{serv.servicio}</h3>
                                                </Col>
                                               
                                            </Row>
                                        </Container>
                                       
                                        <div style={{display:'none'}} id={"divdetalles"+serv.id}>
                                        <Container className="containerMargin">
                                            <Row className="fila">
                                                <Col md={2}>
                                                    <div className="circuloDetalles">
                                                        <Form.Label>Dirigido a</Form.Label>
                                                    </div>
                                                </Col>
                                                <Col md={5}>
                                                    <p>{serv.sectores}</p>
                                                </Col>
                                                <Col md={2}>
                                                    <div className="circuloDetalles">
                                                        <Form.Label>Costo sin IVA</Form.Label>
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <p><b>{serv.costo}</b></p>
                                                </Col>
                                            </Row>
                                        </Container>
                                        <Container className="containerMargin">
                                            <Row className="fila">
                                                <Col md={2}>
                                                    <div className="circuloDetalles">
                                                        <Form.Label>Descripción</Form.Label>
                                                    </div>
                                                </Col>
                                                <Col md={5}>
                                                    <p className="justificacion">{serv.descripcion}</p>
                                                </Col>
                                                <Col md={2}>
                                                    <div className="circuloDetalles">
                                                        <Form.Label>Entregable</Form.Label>
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <p>{serv.entregable}</p>
                                                </Col>
                                            </Row>
                                        </Container>
                                        <Container className="containerMargin">
                                            <Row className="fila">
                                                <Col md={2}>

                                                </Col>
                                                <Col md={5}>
                                                </Col>
                                                <Col md={2}>
                                                    <div className="circuloDetalles">
                                                        <Form.Label>Contacto</Form.Label>
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <p>{serv.contacto}</p>
                                                    <p>{serv.correo}</p>
                                                </Col>
                                            </Row>
                                        </Container>
                                       
                                        </div>
                                        <hr className="linea"></hr>
                                        </>
                                        ))
                                 }
                                 <br></br>
                                <Pagination
                                total={totalPages}
                                current={currentPage}
                                onPageChange={page => handlePageChange(page)}
                                />
                                </>
                            )
                        }
                        {/*servicio != null &&
                            (
                                <>
                                <Container>
                                    <Row>
                                        <Col md={12}>
                                            <div className="circulo">
                                                <h2>{servicio[0].unidad_academica}</h2>
                                            </div>                                        
                                        </Col>
                                    </Row>
                                </Container>
                                <Container>
                                    <Row>
                                        <Col className="divTitulo" md={12}>
                                            <h3>{servicio[0].servicio}</h3>
                                        </Col>
                                    </Row>
                                </Container>
                                <Container>
                                    <Row className="fila">
                                        <Col md={2}>
                                            <div className="circuloDetalles">
                                                <Form.Label>Dirigido a</Form.Label>
                                            </div>
                                        </Col>
                                        <Col md={5}>
                                            <p>{servicio[0].sectores}</p>
                                        </Col>
                                        <Col md={2}>
                                        <div className="circuloDetalles">
                                                <Form.Label>Costo sin IVA</Form.Label>
                                            </div>
                                        </Col>
                                        <Col md={3}>
                                            <p><b>{servicio[0].costo}</b></p>
                                        </Col>
                                    </Row>
                                </Container>
                                <Container>
                                    <Row className="fila">
                                        <Col md={2}>
                                            <div className="circuloDetalles">
                                                <Form.Label>Descripción</Form.Label>
                                            </div>
                                        </Col>
                                        <Col md={5}>
                                            <p className="justificacion">{servicio[0].descripcion}</p>
                                        </Col>
                                        <Col md={2}>
                                        <div className="circuloDetalles">
                                                <Form.Label>Entregable</Form.Label>
                                            </div>
                                        </Col>
                                        <Col md={3}>
                                            <p>{servicio[0].entregable}</p>
                                        </Col>
                                    </Row>
                                </Container>
                                <Container>
                                    <Row className="fila">
                                        <Col md={2}>
                                            
                                        </Col>
                                        <Col md={5}>
                                        </Col>
                                        <Col md={2}>
                                        <div className="circuloDetalles">
                                                <Form.Label>Contacto</Form.Label>
                                            </div>
                                        </Col>
                                        <Col md={3}>
                                            <p>{servicio[0].contacto}</p>
                                            <p>{servicio[0].correo}</p>
                                        </Col>
                                    </Row>
                                </Container>
                                
                                </>
                            )*/}
                            
                    </div>
                </div>
            </div>
        </div></>
    )
}
export default VerServicio;