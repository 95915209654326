import React, { useCallback,useEffect, useState } from "react";
import { Col, Container, Form, InputGroup, Row} from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import './Servicios.css';
import { getServiciosApi } from "../../Api/Servicios";
import {map} from 'lodash';
import Pagination from 'react-responsive-pagination';
// Bootstrap 4.x styles included somewhere in the project
import 'bootstrap/dist/css/bootstrap.css';
import ban1 from "../../assets/banner/ban1.jpeg";
import ban2 from "../../assets/banner/ban2.png";
import ban3 from "../../assets/banner/ban3.jpeg";
import ban4 from "../../assets/banner/ban4.png";
import ban7 from "../../assets/banner/ban7.png";
import ban6 from "../../assets/banner/ban6.png";
import ban5 from "../../assets/banner/ban5.png";
import ban9 from "../../assets/banner/ban9.png";
import ban8 from "../../assets/banner/ban8.png";
import Banners from "../Home/Banners";
function Servicios() {
    const [totalPages,setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [servicios,setServicios] = useState(null);
    const [busqueda,setBusqueda] = useState("");
    const [numPagina,setNumPorPagina] = useState(10);
    const [categoria,setCategoria] = useState("");
    const getServicios = useCallback( async(pagina,busq,numPag,cat) => {
        setBusqueda(busq);
        //console.log(buscar);
        const formdata = {
            "busqueda": busq,
            "pagina": pagina,
            "num_registros":numPag,
            "categoria":cat
         }
        var serv = await getServiciosApi(formdata);
        setServicios(serv.servicios);
        setTotalPages(serv.totalPaginas);
      },[]);
  
    useEffect(() => {
        getServicios(1,"",numPagina,categoria);
    }, [getServicios]) 
    function handlePageChange(page) {
        setCurrentPage(page);
        //console.log("controler normal",page);
        getServicios(page,busqueda,numPagina,categoria);
        // ... do something with `page`
      }
      var setBusquedaFuncion = (valor) =>{
        setCurrentPage(1);
        getServicios(1,valor,numPagina,categoria);        
     }
     var setPorPagina = (numPag) =>{
        setNumPorPagina(numPag);
        setCurrentPage(1);
        getServicios(1,busqueda,numPag,categoria);        
     }
     var busquedaCategorias = (valor) =>{
       console.log('ir a url categorias',valor);
     }
    return(
        <>
        <Banners />
        <div className="contenidoLogin">
            <div className='login'>
                <div className="inicio cursorEstilo">
                    <div className="circuloBuap">
                        <h2>Catálogo de Servicios BUAP</h2>
                    </div>
                </div>
           
                <div className='cuerpoLogin'>
                
                    <div className='cuadroLogin'>
                   
                        <div className="containerLogin">
                        
                            </div>
                            <div className="containerLogin">
                               <Container>
                                 <Row className="fila">
                                    <Col md={4}>
                                        <InputGroup >
                                            <a className="cursorEstilo" href={`/servicio/1`}>
                                                <img src={ban1}
                                                 className="imagenServicio"
                                            
                                                />
                                                <div className="circuloServicios">
                                                    <h2>Capacitación</h2>
                                                </div>
                                            </a>
                                        </InputGroup>
                                        &nbsp;
                                        
                                    </Col>
                                    <Col md={4}>
                                        <InputGroup >
                                            <a className="cursorEstilo" href={`/servicio/2`}>
                                                <img src={ban2}
                                                className="imagenServicio"
                                                
                                                />
                                                <div className="circuloServicios">
                                                    <h2>Servicios Profesionales</h2>
                                                </div>
                                            </a>
                                        </InputGroup>
                                        &nbsp;
                                    </Col>
                                    <Col md={4}>
                                        <InputGroup>
                                            <a className="cursorEstilo" href={`/servicio/3`}>
                                                <img src={ban3}
                                                className="imagenServicio"
                                                
                                                />
                                                <div className="circuloServicios">
                                                    <h2>Servicios a la Comunidad</h2>
                                                </div>
                                            </a>
                                            &nbsp;
                                           
                                        </InputGroup>
                                    </Col>
                                 </Row>
                                 
                               </Container>
                               <Container>
                                 <Row className="fila">
                                    <Col md={4}>
                                        <InputGroup >
                                            <a className="cursorEstilo" href={`/servicio/4`}>
                                                <img src={ban4}
                                                 className="imagenServicio"
                                            
                                                />
                                                <div className="circuloServicios">
                                                    <h2>Diplomados</h2>
                                                </div>
                                            </a>
                                        </InputGroup>
                                        &nbsp;
                                        
                                    </Col>
                                    <Col md={4}>
                                        <InputGroup >
                                            <a className="cursorEstilo" href={`/servicio/5`}>
                                                <img src={ban5}
                                                className="imagenServicio"
                                                
                                                />
                                                <div className="circuloServicios">
                                                    <h2>Cursos y Talleres</h2>
                                                </div>
                                            </a>
                                        </InputGroup>
                                        &nbsp;
                                    </Col>
                                    <Col md={4}>
                                        <InputGroup>
                                            <a className="cursorEstilo" href={`/servicio/6`}>
                                                <img src={ban7}
                                                className="imagenServicio"
                                                
                                                />
                                                <div className="circuloServicios">
                                                    <h2>Certificaciones</h2>
                                                </div>
                                            </a>
                                            &nbsp;
                                           
                                        </InputGroup>
                                    </Col>
                                 </Row>
                                 
                               </Container>
                               <Container>
                                 <Row className="fila">
                                    <Col md={4}>
                                        <InputGroup >
                                            <a className="cursorEstilo" href={`/servicio/7`}>
                                                <img src={ban6}
                                                 className="imagenServicio"
                                            
                                                />
                                                <div className="circuloServicios">
                                                    <h2>Consultoría</h2>
                                                </div>
                                            </a>
                                        </InputGroup>
                                        &nbsp;
                                        
                                    </Col>
                                    <Col md={4}>
                                        <InputGroup >
                                            <a className="cursorEstilo" href={`/servicio/8`}>
                                                <img src={ban8}
                                                className="imagenServicio"
                                                
                                                />
                                                <div className="circuloServicios">
                                                    <h2>Servicios de Laboratorio</h2>
                                                </div>
                                            </a>
                                        </InputGroup>
                                        &nbsp;
                                    </Col>
                                    <Col md={4}>
                                        <InputGroup>
                                            <a className="cursorEstilo" href={`/servicio/9`}>
                                                <img src={ban9}
                                                className="imagenServicio"
                                                
                                                />
                                                <div className="circuloServicios">
                                                    <h2>ATENCIÓN A ESTUDIANTES</h2>
                                                </div>
                                            </a>
                                            &nbsp;
                                           
                                        </InputGroup>
                                    </Col>
                                 </Row>
                                 
                               </Container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
export default Servicios;