import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter as Router,
  Link,
  NavLink,
  Route
} from "react-router-dom";
import { Nav, Navbar} from 'react-bootstrap';
import Home from '../components/Home/Home';
import VerServicio from '../components/Servicios/VerServicio';
import logo from '../assets/logovinculacion.png';
import Servicios from '../components/Servicios/Servicios';
import Consultores from '../components/Consultores/Consultores';
import Patentes from '../components/Patentes/Patentes';
function Appbar() {
  return (
    <Router>
    <Navbar style={{backgroundColor:'#00395E'}}  variant="dark" expand="lg">
        <img
          src={logo}
          width="135"
          height="30"
          className="d-inline-block align-top"
          alt="React Bootstrap logo"
        />
        &nbsp;&nbsp;
        
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" style={{flexDirection:'row-reverse'}}>
          <Nav>
            <Nav.Link as={NavLink} to="/">Inicio</Nav.Link>
            <Nav.Link as={NavLink} to="/servicios">Servicios</Nav.Link>
            <Nav.Link as={NavLink} to="/consultores">Consultores</Nav.Link>
            <Nav.Link as={NavLink} to="/patentes">Patentes</Nav.Link>
          </Nav>

        </Navbar.Collapse>
      </Navbar>
        <Route path="/" exact component={Home}></Route>
        <Route path="/patentes" exact component={Patentes}></Route>
        <Route path="/consultores" exact component={Consultores}></Route>
        <Route path="/servicios" exact component={Servicios}></Route>
        <Route path="/servicio/:id" exact component={VerServicio}></Route>       
    </Router>
  );
}

export default Appbar;
