import React, { useCallback,useEffect, useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row} from "react-bootstrap";
import '../Servicios/Servicios.css';
import {map} from 'lodash';
import Pagination from 'react-responsive-pagination';
// Bootstrap 4.x styles included somewhere in the project
import 'bootstrap/dist/css/bootstrap.css';
import Banners from "../Home/Banners";
import user from "../../assets/consultores/user.png"
import { getConsultoresApi } from "../../Api/Consultores";
import { API_IMAGE } from "../../utils/constants";

function Consultores(){
    const [totalPages,setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [busqueda,setBusqueda] = useState("");
    const [consultores,setConsultores] = useState(null);
    const [numPagina,setNumPorPagina] = useState(10);
    const getConsultores = useCallback( async(pagina,busq,numPag) => {
        setBusqueda(busq);
        //console.log(buscar);
        const formdata = {
            "busqueda": busq,
            "pagina": pagina,
            "num_registros":numPag,
         }
        var serv = await getConsultoresApi(formdata);
        setConsultores(serv.consultores);
        setTotalPages(serv.totalPaginas);
      },[]);
      useEffect(() => {
        getConsultores(1,"",numPagina);
    }, [getConsultores]) 
    var setBusquedaFuncion = (valor) =>{
        setCurrentPage(1);
        getConsultores(1,valor,numPagina);        
     }
     var setPorPagina = (numPag) =>{
        setNumPorPagina(numPag);
        setCurrentPage(1);
        getConsultores(1,busqueda,numPag);        
     }
     function handlePageChange(page) {
        setCurrentPage(page);
        //console.log("controler normal",page);
        getConsultores(page,busqueda,numPagina);
        // ... do something with `page`
      }
      var mostrarOcultar = (id) =>{
        document.getElementById("divdetalles"+id).style.display =(document.getElementById("divdetalles"+id).style.display == 'none' ? 'block':'none')
        let boton = document.getElementById("boton"+id);
        if (boton.classList.contains('mas')) {
            /**remover clase mas y agregar la clase menos */
            boton.classList.remove("mas");
            boton.classList.add("menos");
        }else{
            /**agregar clase menos y quitar la de mas */
            boton.classList.remove("menos");
            boton.classList.add("mas");
        }
    }
    return(
        <>
            <Banners />
            <div className="contenidoLogin">
                <div className='login'>
                    <div className="inicio cursorEstilo">
                        <div className="circuloBuap">
                            <h2>Consultores BUAP</h2>
                        </div>
                    </div>
                    <div className='cuerpoLogin'>
                        
                            <div className="cuadroLoginConsultores">
                                <div className="filtros_pag">
                                    <Form.Group>
                                        <InputGroup>
                                            <Form.Label className="registros">Mostrar</Form.Label>&nbsp;
                                            <Form.Control onChange={event => setPorPagina(event.target.value)} as="select">
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="30">30</option>
                                                <option value="50">50</option>
                                            </Form.Control>&nbsp;
                                            <Form.Label className="registros">registros por página</Form.Label>
                                        </InputGroup>
                                        
                                    </Form.Group>
                                
                                        <Form.Group>
                                            <Form.Control placeholder="Busqueda"   
                                                id="busquedaInput"
                                                name="busquedaInput"
                                                type="text"
                                                onChange={event => setBusquedaFuncion(event.target.value)}
                                            />
                                        </Form.Group>
                                    
                                </div>
                                <div className='cuerpoLogin'>
                                    <a href={`/`} class="hrefa previous">&laquo; Atrás</a>
                                </div>
                                {
                                    consultores != null &&(
                                        <>
                                        {
                                            map(consultores,(consul,index)=>(
                                                <Container>
                                                    <Row>
                                                        <Col md={4}>
                                                            <img 
                                                            onClick={() => mostrarOcultar(consul.id)}
                                                            src={`${API_IMAGE}${consul.foto}`}
                                                            className="imagenConsultor cursorEstilo" 
                                                            />
                                                        </Col>
                                                        <Col md={8}>
                                                            <Row>
                                                                <Col  md={12} >
                                                                    <Row>
                                                                        <Form.Label className="cursorEstilo" onClick={() => mostrarOcultar(consul.id)}>
                                                                            <b>{consul.nombre}</b>
                                                                        </Form.Label>
                                                                    </Row>
                                                                    <Row>
                                                                        <Form.Label className="cursorEstilo" onClick={() => mostrarOcultar(consul.id)}>
                                                                            <b>{consul.adscripcion}</b>
                                                                        </Form.Label>
                                                                    </Row>
                                                                    <Row>
                                                                        <Form.Label className="cursorEstilo" onClick={() => mostrarOcultar(consul.id)}>
                                                                            <b>{consul.area}</b>
                                                                        </Form.Label>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={12}>
                                                                    <Button id={"boton"+consul.id} className="boton mas" onClick={() => mostrarOcultar(consul.id)}></Button>
                                                                    <div  style={{display:'none'}} id={"divdetalles"+consul.id}>
                                                                        <Row>
                                                                            <p><b>Especialidad:</b></p>&nbsp;
                                                                            <Form.Label className="justificacion">
                                                                                {consul.especialidad}
                                                                            </Form.Label>
                                                                        </Row>
                                                                        <Row>
                                                                            <p><b>Semblanza:</b></p>&nbsp;
                                                                            <Form.Label className="justificacion">
                                                                                {consul.semblanza}
                                                                            </Form.Label>
                                                                        </Row>
                                                                    </div>
                                                                    
                                                                    
                                                                </Col>
                                                               
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    &nbsp;
                                                </Container>                                
                                            ))
                                        }
                                        </>
                                    )
                                }
                                <Pagination
                                total={totalPages}
                                current={currentPage}
                                onPageChange={page => handlePageChange(page)}
                                />
                            </div>
                        
                    </div>
                </div>
            </div>
        </>
    )
}
export default Consultores;